import React, { Fragment, useContext, useEffect, useState } from "react"
import styled from "styled-components"
import SEO from "../components/seo"
import { Context } from "../components/context"
import Img from "gatsby-image"
import Layout from "../components/layout"
import {
  Container,
  SectionHeading,
  SectionPage,
  SectionPageTitle,
} from "../components/Section"
import PrimaryOutlineButton from "../components/Button/PrimaryOutlineButton"
import CloseIcon from "../components/Icons/CloseIcon"
import SecondaryButton from "../components/Button/SecondaryButton"
import { Link } from "gatsby"
import { getProductUrl } from "../utils/product"
import RelatedProductCarousel from "../components/RelatedProductCarousel"

const TableCompare = styled.div`
  display: block;
  width: 100%;
  overflow-x: auto;
  overflow-y: visible;
  padding: 0;
  position: relative;

  table {
    margin: 0;
  }
`

const Table = styled.table`
  table-layout: fixed;
  tr {
    border: 0;
    border-bottom: 1px solid #f7f7f7;
  }
  tr:first-child {
    border-top: 1px solid #f7f7f7;
  }

  th:first-child,
  td:first-child {
    padding-left: 10px;
  }

  th.pro-heading {
    position: sticky;
    left: 0;
    z-index: 3;
    text-align: right;
    font-weight: 500;
    background: #ff7245;
    color: #fff;
    border-right: 2px solid #f7f7f7;
    width: 120px;
    min-width: 120px;
    font-size: 11px;
    @media (min-width: 768px) {
      width: 150px;
      min-width: 150px;
      font-size: 15px;
    }
  }

  th.product-origin {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 3;
  }

  td.pro-title {
    padding: 8px 30px;
    font-size: 18px;
    line-height: 28px;
    font-weight: 700;
    background-color: #ebf6ff;
    text-align: left;
    color: #062c44;
  }
  td {
    padding: 8px 10px;
    border-right: 1px solid #fff;
    border-bottom: 1px solid #fff;
    text-align: left;
    background: #deedfd;
    font-size: 14px;
    line-height: 22px;
    color: #062c44;
  }
  thead th {
    background: #deedfd;
  }
  td.pro-desc {
    p {
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 0;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  td.product-thumb {
    text-align: center;
    .gatsby-image-wrapper,
    img {
      width: 200px;
      padding: 4px;
      background-color: #fff;
      border: 1px solid #ddd;
    }
  }
  td:nth-child(2n + 3) {
    background: #cae2fc;
  }
  thead th:nth-child(2n + 3) {
    background: #cae2fc;
  }
  td.product-desc {
    vertical-align: top;
  }
  td.action {
    text-align: center;
    .btn {
      font-size: 14px;
      line-height: 20px;
      padding: 3px 9px;
      font-weight: 400;
    }
  }
`

const CloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
  min-width: 24px;
  svg {
    fill: #062c44;
  }
  &:hover {
    border-color: #ff3e00;
    svg {
      fill: #ff3e00;
    }
  }
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  button,
  a {
    margin: 0 5px;
  }
`

const ProductColHeader = styled.th`
  position: sticky;
  top: 0;
  z-index: 2;
  width: 250px;

  @media (min-width: 1200px) {
    width: unset;
  }
`
const ProductHeaderTitle = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    color: #000;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 5px;
  }
`

const ImageThumb = styled.div`
  width: 100%;
  overflow: hidden;

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`

const AddProduct = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: center;
    font-size: 24px;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    color: #ff7245;
    border: 2px solid #ff7245;
    margin-bottom: 10px;
  }
  .text {
    color: #062c44;
    font-size: 16px;
    font-weight: 500;
  }
`

const displayFields = [
  "Price",
  "Discount",
  "SKU",
  "Gauge",
  "Roof Style",
  "Building Dimensions (WxL)",
  "Square Footage (Sq. Ft.)",
  "Height",
  "RTO & Financing",
  "Description",
  "Actions",
]

const ProductCompare = ({ location }) => {
  const {
    compareProducts,
    addProductToComparison,
    selectProduct,
    removeProduct,
  } = useContext(Context)

  const getServies = product => {
    const services = []
    if (product.productServices) {
      product.productServices.forEach(function(item) {
        if (item.service === "Financing Available") {
          services.push("Financing Available")
        }

        if (item.service === "Rent To Own Available") {
          services.push("Rent To Own Available")
        }
      })
    }
    return services.join(", ")
  }

  const renderCell = (field, product, no) => {
    if (!product) {
      if (field !== "Product") {
        if (!compareProducts || compareProducts.length === 0) return null

        return <td key={no}></td>
      }
    }

    switch (field) {
      case "Product":
        return (
          <ProductColHeader key={no}>
            {product ? (
              <Fragment>
                <ProductHeaderTitle>
                  <span>{product.title}</span>
                  <CloseButton onClick={() => removeProduct(product.id)}>
                    <CloseIcon />
                  </CloseButton>
                </ProductHeaderTitle>
                <ImageThumb>
                  {product.image ? (
                    <Img fluid={product.image.fluid} alt={product.title} />
                  ) : (
                    <img src={product.imageSrc} alt={product.title} />
                  )}
                </ImageThumb>
              </Fragment>
            ) : (
              <Fragment>
                <AddProduct
                  onClick={async () => {
                    const p = await selectProduct()
                    addProductToComparison("select", p)
                  }}
                >
                  <span className="icon">+</span>
                  <span className="text">Add a Product</span>
                </AddProduct>
              </Fragment>
            )}
          </ProductColHeader>
        )
      case "Price":
        return (
          <td key={no}>
            <strong>${product.price.toLocaleString("en-US")}</strong>
          </td>
        )
      case "Discount":
        let finalPrice = product.price
        if (product.price > 20000) {
          finalPrice = finalPrice * 0.85
        } else if (product.price > 10000) {
          finalPrice = finalPrice * 0.9
        } else if (product.price > 5000) {
          finalPrice = finalPrice * 0.95
        }
        return <td key={no}>${finalPrice}</td>
      case "SKU":
        return <td key={no}>PB#{product.skuNumber}</td>
      case "Gauge":
        return <td key={no}>{product.gauge}</td>
      case "Roof Style":
        return <td key={no}>{product.productRoofType?.name}</td>
      case "Building Dimensions (WxL)":
        return <td key={no}>{`${product.width}'x${product.length}'`}</td>
      case "Square Footage (Sq. Ft.)":
        return <td key={no}>{`${product.width * product.length}sqft`}</td>
      case "Height":
        return <td key={no}>{product.height}</td>
      case "RTO & Financing":
        return <td key={no}>{getServies(product)}</td>
      case "Description":
        return (
          <td key={no} className="product-desc">
            {product.description.description}
          </td>
        )
      case "Actions":
        const productUrl = getProductUrl(product)
        return (
          <td className="action" key={no}>
            <Actions>
              {/* <button
                type="button"
                aria-label="get a quote"
                onClick={HandleModalOpen}
              >
                <SecondaryButton text="Get a Quote" />
              </button> */}
              <a href="tel:8777541818" aria-label="(877) 754-1818" >
                <SecondaryButton text="Call Us Now" />
              </a>

              <Link to={productUrl}>
                <PrimaryOutlineButton text="View Details" />
              </Link>
            </Actions>
          </td>
        )
      default:
        return null
    }
  }

  const renderHeadLabel = field => {
    if (!compareProducts || compareProducts.length === 0) return null
    return <th className="pro-heading">{field}</th>
  }

  const products = [...compareProducts];
  while (products.length < 4) {
    products.push(null);
  }

  return (
    <Fragment>
      <SectionPageTitle>Product Comparison</SectionPageTitle>

      <TableCompare>
        <Table className="table">
          <thead>
            <tr>
              <th className="pro-heading product-origin">Product</th>
              {products.map((p, i) => renderCell("Product", p, i))}
            </tr>
          </thead>
          <tbody>
            {displayFields.map((field, j) => (
              <tr key={j}>
                {renderHeadLabel(field)}
                {products.map((p, i) => renderCell(field, p, i))}
              </tr>
            ))}
          </tbody>
        </Table>
      </TableCompare>
    </Fragment>
  )
}

const SimilarProducts = ({ data }) => {
  const { compareProducts } = useContext(Context)

  if (!compareProducts || compareProducts.length === 0) return null

  const categories = compareProducts.map(p => p.productCategory.name)
  const compareProductIds = compareProducts.map(p => p.id)
  const products = data.allContentfulProduct.edges.filter(
    edge =>
      edge.node.productCategory &&
      edge.node.productCategory.name &&
      edge.node.productRoofType &&
      edge.node.productRoofType.name &&
      categories.indexOf(edge.node.productCategory.name) >= 0 &&
      compareProductIds.indexOf(edge.node.id) < 0
  )

  return (
    <Container>
      <SectionHeading textAlign="center" mb="40px">
        Similar Buildings
      </SectionHeading>
      <RelatedProductCarousel data={products} />
    </Container>
  )
}

const Page = ({ data, location }) => {
  return (
    <Layout location={location}>
      <SEO
        title="Compare Metal Buildings, Specs & Features | Probuilt Steel Buildings"
        description="Discover and compare metal buildings to make an informed choice. Compare the specifications, prices, and features of different types of metal buildings to find the perfect one that suits your needs."
      />
      <SectionPage
        ept="160px"
        epb="60px"
        xpt="160px"
        xpb="40px"
        pt="100px"
        pb="30px"
        bg="#F4FBFF"
      >
        <Container>
          <ProductCompare location={location} />
        </Container>
      </SectionPage>

      <SectionPage
        ept="40px"
        epb="60px"
        xpt="40px"
        xpb="40px"
        pt="40px"
        pb="30px"
        bg="#fff"
      >
        <SimilarProducts data={data} />
      </SectionPage>
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query ComparePageQuery {
    allContentfulProduct(sort: { fields: skuNumber }) {
      edges {
        node {
          id
          senseiProductId
          skuNumber
          imageSrc
          image {
            fluid(maxWidth: 700, quality: 100) {
              base64
              sizes
              aspectRatio
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          title
          width
          length
          height
          price
          priceDescription
          gauge
          productServices {
            service
            image {
              fluid(maxWidth: 45, quality: 100) {
                aspectRatio
                base64
                sizes
                src
                srcWebp
                srcSetWebp
                srcSet
              }
            }
          }
          productRoofType {
            name
          }
          productCategory {
            name
          }
          description {
            description
          }
          numberOfSale
          canBeUsedAs {
            content
          }
        }
      }
    }
  }
`
